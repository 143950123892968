.container
  position: relative

.promptContainer
  color: #3d4854
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.14)
  // border-radius: 8
  position: fixed
  width: 100%
  padding: 20px 20px
  background: #FFF
  z-index: 5
  left: 0px
  border-radius: 8px
  box-sizing: border-box
  width: 1200px
  left: 50%
  transform: translate(-50%)

  @media only screen and (min-width: 768px)
    width: 750px

  @media only screen and (min-width: 990px)
    width: 970px

  @media only screen and (min-width: 1200px)
    width: 1200px

  @media only screen and (max-width: 767px)
    width: 100%
    border-radius: 0px
    // height: 350px

.overlay
  @media only screen and (max-width: 767px)
    z-index: 1
    position: fixed
    width: 100%
    height: 100%
    top: 0
    left: 0
    bottom: 0
    right: 0
    background: rgba(0, 0, 0, .25)

.phone
  color: #EA580C

.link
  width: 100%
  color: #EA580C
  text-decoration: none

.relative
  position: relative

.logoRow
  display: flex
  align-items: center
  @media only screen and (max-width: 767px)
    flex-direction: column

.logo
  height: 40px
  margin-right: 16px
  object-fit: contain

  @media only screen and (max-width: 767px)
    margin-right: 0px
    margin-bottom: 16px

  @media only screen and (min-width: 768px)
    margin-bottom: 8px

.row
  display: flex
  justify-content: space-between
  max-width: 1100px
  margin: 0 auto

  @media only screen and (max-width: 767px)
    flex-direction: column
    justify-content: center
    height: 100%

.column
  text-align: left
  margin-right: 16px
  display: flex
  align-items: center
  @media only screen and (max-width: 767px)
    text-align: center
    margin-right: 0

.header
  margin-top: 0
  margin-bottom: 0

  @media only screen and (max-width: 1199px)
    font-size: 25px

.buttonRow
  font-size: 16px

.closeButton
  position: absolute
  top: 0
  right: 0
  padding: 16px
  z-index: 3
  font-size: 18px
  border-radius: 50%
  height: 25px
  width: 25px
  display: flex
  align-items: center
  justify-content: center
  background: #FFF
  cursor: pointer
  @media only screen and (max-width: 767px)
    display: none

.name
  color: #EA580C
  text-transform: capitalize

.button
  font-size: 18px
  background: #EA580C
  padding: 0px 30px
  color: #fff!important
  border: 0
  text-decoration: none
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0
  margin-right: 16px
  text-transform: capitalize
  border-radius: 4px

  @media only screen and (max-width: 767px)
    margin-top: 16px
    margin-right: 0px
    height: 40px

.dismissButton
  background: #fff
  color: #EA580C
  border: 1px solid #ddd
  text-transform: capitalize
  font-size: 18px

  @media only screen and (max-width: 767px)
    height: 40px
    margin-top: 16px

  @media only screen and (min-width: 768px)
    display: none

.innerButton
  height: 43px
